<template>
  <button
    :type="type"
    :disabled="disabled"
    :class="[
      'base-btn',
      'inline-block w-full max-w-[288px] rounded-lg px-3 text-center text-base font-bold outline-none md:px-6',
      variantClass,
    ]"
  >
    <slot />
  </button>
</template>

<script setup lang="ts">
const props = defineProps({
  variant: {
    type: String,
    default: 'outline',
  },
  type: {
    type: String as PropType<'submit' | 'reset' | 'button'>,
    default: 'button',
  },
  disabled: Boolean,
})

const defaultVariant = {
  primary:
    'bg-beyond-blue text-white hover:bg-primary hover:border-white active:bg-primary py-4 focus:outline-primary focus:bg-beyond-blue',
  outline:
    'border-beyond-blue py-[14px] text-primary hover:bg-[#DCE8ED] hover:border-[#DCE8ED] border-2 hover:text-primary active:border-secondary active:bg-[#DCE8ED] focus:bg-white active:outline-primary focus:outline-primary',
  'outline-secondary':
    'border-secondary py-[14px] text-beyond-blue bg-white hover:bg-[#DCE8ED] hover:border-[#DCE8ED] border-2 hover:text-secondary active:border-secondary active:bg-[#E5F1F7] active:outline-primary focus:outline-primary',
} as { [key: string]: string }

const variantClass = computed(() => {
  return defaultVariant[props.variant]
})
</script>
